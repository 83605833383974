import React from "react";
import { Box, useTheme, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import KaCite from "./KaCite";

const useStyles = makeStyles(theme => ({
  root: {
    color: "white"
  },
  image: {
    backgroundSize: "cover"
  }
}));

const KaDialogContentMainImageInfo = ({
  image,
  mainContent,
  infoContent,
  infoFootnote
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const space = mobile ? 2 : 3;

  const imgStyle = {
    backgroundImage: "url(" + image + ")"
  };

  const img = (
    <Box
      className={classes.image}
      height={mobile ? 180 : 270}
      width={mobile ? "auto" : 370}
      mb={space}
      bgcolor="#eee"
      style={imgStyle}
    ></Box>
  );

  return (
    <Box
      p={space}
      display="flex"
      flexDirection={mobile ? "column" : "row"}
      className={classes.root}
    >
      {mobile && img}
      <Box
        flexGrow={1}
        bgcolor={theme.palette.primary.main}
        mr={mobile ? 0 : space}
        mb={mobile ? space : 0}
        px={mobile ? space : 4}
        py={mobile ? space : 0}
        display="flex"
        alignItems="center"
        width="100%"
      >
        {mainContent}
      </Box>
      <Box>
        {!mobile && img}
        <KaCite
          height={mobile ? 180 : 270}
          width={mobile ? "auto" : 370}
          content={infoContent}
          footnote={infoFootnote}
        />
      </Box>
    </Box>
  );
};

export default KaDialogContentMainImageInfo;
