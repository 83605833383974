import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import Img from "../../assets/kaemmerer_mandanten.jpg";
import KaBoxRow from "../shared/KaBoxRow";
import KaBox from "../shared/KaBox";
import useWindowSize from "../../hooks/useWindowSize";
import HomeIhreErwartungen from "./HomeIhreErwartungen";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeUnsereErwartungen from "./HomeUnsereErwartungen";
import HomeGemeinsamerWeg from "./HomeGemeinsamerWeg";
import KaCategoryHeader from "../shared/KaCategoryHeader";
import KaMap from "../shared/KaMap";
import KanzleiTeam from "./KanzleiTeam";
import KanzleiFlexibilitaet from "./KanzleiFlexibilitaet";
import KanzleiEngagament from "./KanzleiEngagament";
import KanzleiKarriere from "./KanzleiKarriere";
import SpektrumSteuerberatung from "./SpektrumSteuerberatung";
import SpektrumGenerationZ from "./SpektrumGenerationZ";
import SpektrumTaetigkeitsfelder from "./SpektrumTaetigkeitsfelder";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({}));

const Spektrum = ({ match }) => {
  const classes = useStyles();
  const [windowWidth, windowHeight] = useWindowSize();
  const [sliderHeight, setSliderHeight] = useState(0);
  const [currentDialog, setCurrentDialog] = useState(null);
  const [closed, setClosed] = useState(false);
  const dispatch = useDispatch();
  const currentSlideIndex = useSelector(state => state.slider.currentIndex);
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();
  const history = useHistory();

  useEffect(() => {
    if (!match.params.dialog) {
      setClosed(false);
    }
    setCurrentDialog(match.params.dialog || null);
  }, [match.params.dialog]);

  useEffect(() => {
    if (!xs) {
      const availableHeight = windowHeight - 80 - 252 - 200;
      setSliderHeight(availableHeight);
    } else {
      setSliderHeight(240);
    }
  }, [windowHeight, windowWidth]);

  const onPrev = () => {
    dispatch({ type: "SET_PREV_SLIDE" });
  };

  const onNext = () => {
    dispatch({ type: "SET_NEXT_SLIDE" });
  };

  const onUpdateSlider = event => {
    // console.log(event);
  };

  if (closed) {
    setTimeout(() => {
      history.push("/spektrum");
    }, 100);
    // return <Redirect to="/spektrum" push />;
  }

  // console.log(currentDialog);

  const infoContent = (
    <>
      Hindernisse und Schwierigkeiten
      <br />
      sind <strong>Stufen</strong>, auf denen wir <br />
      in die Höhe <strong>steigen</strong>
    </>
  );

  return (
    <>
      <KaCategoryHeader
        title={null}
        subtitle={<strong>Professionalität:</strong>}
        subtitle2={
          <>
            Unser Einsatz für Ihre
            <br />
            Interessen
          </>
        }
        img={Img}
        infoContent={infoContent}
        infoFootnote="Friedrich Nietzsche"
        showMore={false}
        showPlus={false}
      />
      <KaBoxRow last>
        <KaBox
          title="Steuerberatung"
          to="/spektrum/steuerberatung"
          align="center"
        ></KaBox>
        <KaBox
          align="center"
          title="Tätigkeitsfelder"
          to="/spektrum/taetigkeitsfelder"
        ></KaBox>
        <KaBox
          title="Generation Z"
          to="/spektrum/generation-z"
          align="center"
          last
        ></KaBox>
      </KaBoxRow>
      <SpektrumSteuerberatung
        open={currentDialog === "steuerberatung"}
        close={() => {
          setClosed(true);
        }}
      />
      <SpektrumGenerationZ
        open={currentDialog === "generation-z"}
        close={() => {
          setClosed(true);
        }}
      />

      <SpektrumTaetigkeitsfelder
        open={currentDialog === "taetigkeitsfelder"}
        close={() => {
          setClosed(true);
        }}
      />

      <KaMap />
    </>
  );
};

export default Spektrum;
