const initState = {
  currentIndex: 0
};

const sliderReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_NEXT_SLIDE":
      return {
        ...state,
        currentIndex: state.currentIndex + 1
      };
    case "SET_PREV_SLIDE":
      return {
        ...state,
        currentIndex: state.currentIndex - 1
      };
    case "SET_CURRENT_INDEX":
      return {
        ...state,
        currentIndex: action.index
      };
    default:
      return state;
  }
};

export default sliderReducer;
