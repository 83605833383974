import React from "react";
import KaDialog from "../shared/KaDialog";
import Img from "../../assets/engagement.jpg";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "cover",
    backgroundPosition: "top center"
  },
  paragraph: {
    margin: theme.spacing(3, 0)
  }
}));

const KanzleiEngagament = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();

  return (
    <>
      <KaDialog open={open} close={close}>
        <>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            p={{ sm: 3 }}
            height={xs ? undefined : sm ? 780 : 700}
          >
            <Box
              p={3}
              className={classes.img}
              style={{ backgroundImage: "url(" + Img + ")" }}
              flexGrow={1}
              bgcolor={theme.palette.primary.main}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
              height={xs ? 170 : undefined}
              mr={{ sm: 3 }}
            ></Box>
            <Box
              color="white"
              p={{ xs: 3, sm: 4 }}
              flexGrow={1}
              bgcolor={theme.palette.primary.main}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
            >
              <Typography variant="h1" component="h2">
                Engagement
              </Typography>
              <Typography variant="h6" component="h3" gutterBottom>
                Unser Engagement für Ihren Erfolg
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Die komplexen und vielfältigen Herausforderungen einer
                umfassenden Beratung erfordern ein Höchstmaß an Vertrauen und
                Zuverlässigkeit.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Deshalb setzen wir den Fokus auf eine persönliche und
                partnerschaftliche Beziehung zu unseren Mandanten. Sie wissen
                wer bei uns Ihr Ansprechpartner ist.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Wenn wir Sie beraten, dann kennen wir Sie, Ihre Belange und
                Interessen. Wir gehen auf Ihre individuellen Anforderungen ein,
                denn bei uns wird Ihr Fall nicht nur verwaltet.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Wir möchten erfolgreich und langfristig mit Ihnen
                zusammenarbeiten
              </Typography>
            </Box>
          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default KanzleiEngagament;
