import React from "react";
import Map from "pigeon-maps";
import { KaMapMarker } from "./KaMapMarker";
import { Box, makeStyles } from "@material-ui/core";
import Img from "../../assets/map_pfeil.png";

const providers = {
  osm: (x, y, z) => {
    const s = String.fromCharCode(97 + ((x + y + z) % 3));
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
  },
  wikimedia: (x, y, z, dpr) => {
    return `https://maps.wikimedia.org/osm-intl/${z}/${x}/${y}${
      dpr >= 2 ? "@2x" : ""
    }.png`;
  },
  stamen: (x, y, z, dpr) => {
    return `https://stamen-tiles.a.ssl.fastly.net/terrain/${z}/${x}/${y}${
      dpr >= 2 ? "@2x" : ""
    }.jpg`;
  }
};

const useStyles = makeStyles(theme => ({
  pfeil: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "auto",
    pointerEvents: "none",
    zIndex: 9
  }
}));

const KaMap = () => {
  const classes = useStyles();
  const position = [49.4466802, 7.7622062];
  return (
    <Box mb={2} position="relative">
      <img src={Img} alt="" className={classes.pfeil} />
      <Map
        center={position}
        zoom={17}
        height={400}
        metaWheelZoom={true}
        provider={providers["osm"]}
      >
        <KaMapMarker
          anchor={position}
          payload={1}
          onClick={({ event, anchor, payload }) => {}}
        />
      </Map>
    </Box>
  );
};

export default KaMap;
