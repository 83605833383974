import React, { useState } from "react";
import KaDialog from "../shared/KaDialog";
import Img from "../../assets/karriere.jpg";
import {
  Box,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Divider,
  TextField,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";
import { grey, green } from "@material-ui/core/colors";
import API from "../../utils/API";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "auto 80%",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat"
  },
  paragraph: {
    margin: theme.spacing(3, 0)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  dividerTransp: {
    height: 0,
    margin: theme.spacing(0.5, 0)
  },
  link: {
    color: "inherit",
    fontWeight: "bold",
    textDecoration: "none"
  },
  check: {
    color: green[500],
    width: 40,
    height: 40
  }
}));

const keyValues = [
  {
    value: "checkedAusbildung",
    title: "Ausbildung zur/zum Steuerfachangestellten (m/w/d)"
  },
  {
    value: "checkedSteuerfachangstellte",
    title: "Steuerfachangestellte (m/w/d)"
  },
  {
    value: "checkedFachassistentLohnGehalt",
    title: "Fachassistent Lohn und Gehalt (m/w/d)"
  },
  {
    value: "checkedFachassistentRechnungswesenControlling",
    title: "Fachassistent Rechnungswesen und Controlling (m/w/d)"
  },
  { value: "checkedSteuerberater", title: "Steuerberater (m/w/d)" }
];

const KanzleiKarriere = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();
  const [state, setState] = useState({
    vorname: "",
    nachname: "",
    email: "",
    telefon: "",
    checkedAusbildung: false,
    checkedSteuerfachangstellte: false,
    checkedFachassistentLohnGehalt: false,
    checkedFachassistentRechnungswesenControlling: false,
    checkedSteuerberater: false
  });
  const [success, setSuccess] = useState(null);

  const handleChange = name => event => {
    if (event.target.hasOwnProperty("checked")) {
      setState({ ...state, [name]: event.target.checked });
    } else {
      setState({ ...state, [name]: event.target.value });
    }
  };

  const handleSend = async () => {
    const body = {
      Vorname: state.vorname,
      Nachname: state.nachname,
      "E-Mail": state.email,
      Telefon: state.telefon,
      Auswahl: keyValues
        .filter(item => state[item.value])
        .map(item => item.title)
        .join(", ")
    };
    try {
      const resultData = await API.post("", body);
      const data = resultData.data ? resultData.data : {};
      setSuccess(data);
    } catch (e) {
      console.log("FEHLER: ", e);
    }
  };

  // console.log("SUCCESS", success);

  const successView = (
    <>
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          width={60}
          height={60}
          bgcolor="white"
          borderRadius={30}
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={4}
        >
          <CheckIcon className={classes.check} />
        </Box>
        <Typography variant="h6" gutterBottom>
          Vielen Dank für Ihre Nachricht!
        </Typography>
        <Typography variant="body2">
          Wir setzen uns mit Ihnen in Verbindung
        </Typography>
      </Box>
    </>
  );

  const valid = state.vorname && state.nachname && state.email && state.telefon;

  const formView = (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedAusbildung}
            onChange={handleChange("checkedAusbildung")}
            value="checkedAusbildung"
          />
        }
        label={
          <Typography variant="overline">
            Ausbildung zur/zum Steuerfachangestellten (m/w/d)
          </Typography>
        }
      />
      <Divider className={classes.divider} />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedSteuerfachangstellte}
            onChange={handleChange("checkedSteuerfachangstellte")}
            value="checkedSteuerfachangstellte"
          />
        }
        label={
          <Typography variant="overline">
            Steuerfachangestellte (m/w/d)
          </Typography>
        }
      />
      <Divider className={classes.divider} />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedFachassistentLohnGehalt}
            onChange={handleChange("checkedFachassistentLohnGehalt")}
            value="checkedFachassistentLohnGehalt"
          />
        }
        label={
          <Typography variant="overline">
            Fachassistent Lohn und Gehalt (m/w/d)
          </Typography>
        }
      />
      <Divider className={classes.divider} />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedFachassistentRechnungswesenControlling}
            onChange={handleChange(
              "checkedFachassistentRechnungswesenControlling"
            )}
            value="checkedFachassistentRechnungswesenControlling"
          />
        }
        label={
          <Typography variant="overline">
            Fachassistent Rechnungswesen und Controlling (m/w/d)
          </Typography>
        }
      />
      <Divider className={classes.divider} />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.checkedSteuerberater}
            onChange={handleChange("checkedSteuerberater")}
            value="checkedSteuerberater"
          />
        }
        label={
          <Typography variant="overline">Steuerberater (m/w/d)</Typography>
        }
      />
      <Divider className={classes.divider} />
      <TextField
        value={state.vorname}
        onChange={handleChange("vorname")}
        fullWidth
        label="Vorname"
        margin="dense"
        variant="filled"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        value={state.nachname}
        onChange={handleChange("nachname")}
        fullWidth
        label="Nachname"
        margin="dense"
        variant="filled"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        type="email"
        value={state.email}
        onChange={handleChange("email")}
        fullWidth
        label="E-Mail-Adresse"
        margin="dense"
        variant="filled"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        value={state.telefon}
        onChange={handleChange("telefon")}
        fullWidth
        label="Rückruf-Telefonnummer"
        margin="dense"
        variant="filled"
      />
      <Divider className={classes.dividerTransp} />
      <Box
        display="flex"
        flexDirection={xs || sm ? "column" : "row"}
        alignItems="center"
      >
        <Typography variant="caption" gutterBottom={xs || sm}>
          Mit Klick auf „absenden“ stimmen Sie unseren{" "}
          <a className={classes.link} href="/datenschutz" target="_blank">
            Datenschutzbestimmungen
          </a>{" "}
          zu.
        </Typography>
        <Button
          fullWidth={xs || sm}
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleSend}
          disabled={!valid}
        >
          Absenden
        </Button>
      </Box>
    </>
  );

  return (
    <>
      <KaDialog open={open} close={close}>
        <>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            p={{ xs: 0, sm: 3 }}
            height={xs ? undefined : sm ? 780 : 700}
          >
            <Box
              display="flex"
              flexDirection="column"
              color="white"
              mr={{ xs: 0, sm: 3 }}
              flexGrow={1}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
            >
              <Box
                p={4}
                bgcolor={theme.palette.primary.main}
                flexGrow={1}
                flexShrink={1}
                flexBasis={xs ? "auto" : "0px"}
              >
                <Typography variant="h1" component="h2">
                  Karriere
                </Typography>
                <Typography variant="h6" component="h3" gutterBottom>
                  Teamplayer gesucht!
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  Wir suchen regelmäßig Verstärkung.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  Da wir eine langfristige Mitarbeiterplanung haben, nehmen wir
                  auch gerne Bewerbungen für Zeiträume entgegen, die noch etwas
                  in der Zukunft liegen.
                </Typography>
                <Typography variant="h6" component="h3">
                  Wir freuen uns, Sie kennenzulernen!
                </Typography>
              </Box>
              <Box
                className={classes.img}
                height={xs ? 200 : undefined}
                style={{ backgroundImage: "url(" + Img + ")" }}
                display="flex"
                justifyContent="center"
                p={3}
                flexGrow={1}
                flexShrink={1}
                flexBasis={xs ? "auto" : "0px"}
              ></Box>
            </Box>
            <Box
              p={3}
              className={classes.img}
              flexGrow={1}
              bgcolor={grey[200]}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
            >
              {Boolean(success) ? successView : formView}
            </Box>
          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default KanzleiKarriere;
