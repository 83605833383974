import React from "react";
import { Helmet } from "react-helmet";

const KaSeo = ({ category }) => {
  let title = "Steuerberatung Kanzlei Kämmerer Kaiserslautern";
  let desc =
    "Ausgezeichnet als “Beste Steuerberater 2022” sind wir für Sie da. Wir beraten Mandanten von Einzelpersonen bis zu mittelständischen Unternehmen.";

  switch (category) {
    case "kanzlei":
      title = "Kanzlei Kämmerer – Ihr Ansprechpartner in allen Steuerfragen";
      desc =
        "Lernen Sie uns kennen. Teamorientiert und rationell bieten wir maßgeschneiderte Lösungen für Ihre Steueranliegen.";
      break;
    case "spektrum":
      title = "Steffen Kämmerer Steuerberatung";
      desc = "Professionalität: Unser Einsatz für Ihre Interessen";
      break;
    case "service":
      title = "Steuerberatung Kämmerer Kaiserslautern";
      desc =
        "Unser Serviceangebot: Alternative Wege für Ihre Belegeinreichung mit höchstem Anwenderkomfort.";
      break;
    case "datenschutz":
      title = "Kanzlei Kämmerer Steuerberatung Kaiserslautern";
      desc =
        "Qualität zum Nutzen unserer Mandanten ist die Basis für unser Geschäft.";
      break;
    case "impressum":
      title = "Bester Steuerberater 2019 – Im Herzen von Kaiserslautern";
      desc =
        "Wir setzen den Fokus auf eine persönliche und partnerschaftliche Beziehung zu unseren Mandanten.";
      break;
    default:
      break;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={desc} />
      </Helmet>
    </>
  );
};

export default KaSeo;
