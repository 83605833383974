import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import Img from "../../assets/kaemmerer-lernen-sie-uns-kennen-2022.jpg";
import KaBoxRow from "../shared/KaBoxRow";
import KaBox from "../shared/KaBox";
import useWindowSize from "../../hooks/useWindowSize";
import HomeIhreErwartungen from "./HomeIhreErwartungen";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeUnsereErwartungen from "./HomeUnsereErwartungen";
import HomeGemeinsamerWeg from "./HomeGemeinsamerWeg";
import KaCategoryHeader from "../shared/KaCategoryHeader";
import KaMap from "../shared/KaMap";
import KanzleiTeam from "./KanzleiTeam";
import KanzleiFlexibilitaet from "./KanzleiFlexibilitaet";
import KanzleiEngagament from "./KanzleiEngagament";
import KanzleiKarriereDownloads from "./KanzleiKarriereDownloads";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({}));

const Kanzlei = ({ match }) => {
  const classes = useStyles();
  const [windowWidth, windowHeight] = useWindowSize();
  const [sliderHeight, setSliderHeight] = useState(0);
  const [currentDialog, setCurrentDialog] = useState(null);
  const [closed, setClosed] = useState(false);
  const dispatch = useDispatch();
  const currentSlideIndex = useSelector(state => state.slider.currentIndex);
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();
  const history = useHistory();

  useEffect(() => {
    if (!match.params.dialog) {
      setClosed(false);
    }
    setCurrentDialog(match.params.dialog || null);
  }, [match.params.dialog]);

  useEffect(() => {
    if (!xs) {
      const availableHeight = windowHeight - 80 - 252 - 200;
      setSliderHeight(availableHeight);
    } else {
      setSliderHeight(240);
    }
  }, [windowHeight, windowWidth]);

  const onPrev = () => {
    dispatch({ type: "SET_PREV_SLIDE" });
  };

  const onNext = () => {
    dispatch({ type: "SET_NEXT_SLIDE" });
  };

  const onUpdateSlider = event => {
    // console.log(event);
  };

  if (closed) {
    //console.log(history);
    setTimeout(() => {
      history.push("/kanzlei");
    }, 100);

    // console.log(history.goBack());
    //return <Redirect to="/kanzlei" push />;
  }

  console.log(currentDialog);

  const infoContent = (
    <>
      Zusammenkommen ist ein <strong>Beginn</strong>,<br />
      Zusammenbleiben ein <strong>Fortschritt</strong>,<br />
      Zusammenarbeiten ein <strong>Erfolg</strong>.
    </>
  );

  return (
    <>
      <KaCategoryHeader
        title="kennen"
        subtitle="Lernen Sie uns"
        linkText="Unser Team"
        to="/kanzlei/team"
        img={Img}
        infoContent={infoContent}
        infoFootnote="Henry Ford"
      />
      <KaBoxRow last>
        <KaBox
          title="Flexibilität"
          to="/kanzlei/flexibilitaet"
          align="center"
        ></KaBox>
        <KaBox
          title="Engagement"
          to="/kanzlei/engagement"
          align="center"
        ></KaBox>
        <KaBox
          title="Karriere"
          to="/kanzlei/karriere"
          align="center"
          last
          light
        ></KaBox>
      </KaBoxRow>
      <KanzleiTeam
        open={currentDialog === "team"}
        close={() => {
          setClosed(true);
        }}
      />
      <KanzleiFlexibilitaet
        open={currentDialog === "flexibilitaet"}
        close={() => {
          setClosed(true);
        }}
      />

      <KanzleiEngagament
        open={currentDialog === "engagement"}
        close={() => {
          setClosed(true);
        }}
      />

      <KanzleiKarriereDownloads
        open={currentDialog === "karriere"}
        close={() => {
          setClosed(true);
        }}
      />

      <KaMap />
    </>
  );
};

export default Kanzlei;
