import React, { useState } from "react";
import KaDialog from "../shared/KaDialog";
import Img from "../../assets/karriere.jpg";
import {
  Box,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Divider,
  TextField,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";
import { grey, green } from "@material-ui/core/colors";
import API from "../../utils/API";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "auto 80%",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat"
  },
  paragraph: {
    margin: theme.spacing(3, 0)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  dividerTransp: {
    height: 0,
    margin: theme.spacing(0.5, 0)
  },
  link: {
    color: "inherit",
    fontWeight: "bold",
    textDecoration: "none"
  },
  textLink: {
    color: theme.palette.secondary.main,
    textDecoration: "none"
  },
  check: {
    color: green[500],
    width: 40,
    height: 40
  }
}));

const Kontakt = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();
  const [state, setState] = useState({
    vorname: "",
    nachname: "",
    email: "",
    telefon: "",
    nachricht: ""
  });
  const [success, setSuccess] = useState(null);

  const handleChange = name => event => {
    if (event.target.hasOwnProperty("checked")) {
      setState({ ...state, [name]: event.target.checked });
    } else {
      setState({ ...state, [name]: event.target.value });
    }
  };

  const handleSend = async () => {
    const body = {
      Vorname: state.vorname,
      Nachname: state.nachname,
      "E-Mail": state.email,
      Telefon: state.telefon,
      Nachricht: state.nachricht
    };
    try {
      const resultData = await API.post("", body);
      const data = resultData.data ? resultData.data : {};
      setSuccess(data);
    } catch (e) {
      console.log("FEHLER: ", e);
    }
  };

  // console.log("SUCCESS", success);

  const successView = (
    <>
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          width={60}
          height={60}
          bgcolor="white"
          borderRadius={30}
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={4}
        >
          <CheckIcon className={classes.check} />
        </Box>
        <Typography variant="h6" gutterBottom>
          Vielen Dank für Ihre Nachricht!
        </Typography>
        <Typography variant="body2">
          Wir setzen uns mit Ihnen in Verbindung
        </Typography>
      </Box>
    </>
  );

  const valid = state.vorname && state.nachname && state.email && state.telefon;

  const formView = (
    <>
      <TextField
        value={state.vorname}
        onChange={handleChange("vorname")}
        fullWidth
        label="Vorname"
        margin="dense"
        variant="filled"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        value={state.nachname}
        onChange={handleChange("nachname")}
        fullWidth
        label="Nachname"
        margin="dense"
        variant="filled"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        type="email"
        value={state.email}
        onChange={handleChange("email")}
        fullWidth
        label="E-Mail-Adresse"
        margin="dense"
        variant="filled"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        value={state.telefon}
        onChange={handleChange("telefon")}
        fullWidth
        label="Rückruf-Telefonnummer"
        margin="dense"
        variant="filled"
      />
      <Divider className={classes.dividerTransp} />
      <TextField
        value={state.nachricht}
        onChange={handleChange("nachricht")}
        fullWidth
        label="Ihre Nachricht"
        multiline
        rows={10}
        margin="dense"
        variant="filled"
      />
      <Divider className={classes.dividerTransp} />
      <Box
        display="flex"
        flexDirection={xs || sm ? "column" : "row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="caption" gutterBottom={xs || sm}>
          Mit Klick auf „absenden“ stimmen Sie unseren{" "}
          <a className={classes.link} href="/datenschutz" target="_blank">
            Datenschutzbestimmungen
          </a>{" "}
          zu.&nbsp;
        </Typography>
        <Button
          fullWidth={xs || sm}
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleSend}
          disabled={!valid}
        >
          Absenden
        </Button>
      </Box>
    </>
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection={xs ? "column" : "row"}
        py={{ xs: 0, sm: 2 }}
        minHeight="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          color="white"
          mr={{ xs: 0, sm: 2 }}
          mt={{ xs: 2, sm: 0 }}
          flexGrow={1}
          flexShrink={1}
          flexBasis={xs ? "auto" : "0px"}
        >
          <Box
            p={4}
            bgcolor={theme.palette.primary.main}
            flexGrow={1}
            flexShrink={1}
            flexBasis={xs ? "auto" : "0px"}
          >
            <Typography variant="h1" component="h2">
              Kontakt
            </Typography>
            <Typography variant="h6" component="h3" gutterBottom>
              Sie interessieren sich für unsere Dienstleistungen? Senden Sie uns
              eine Mail oder rufen Sie uns an, gerne vereinbaren wir einen
              Beratungstermin.
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              Dipl.-Betriebswirt (FH)
              <br />
              Steffen Kämmerer
              <br />
              Steuerberater
              <br />
              Burgstraße 39
              <br />
              67659 Kaiserslautern
              <br />
              Telefon: 0631 / 205776-0
              <br />
              Telefax: 0631 / 205776-99
              <br />
              E-Mail:{" "}
              <a
                className={classes.textLink}
                href="mailto:info@stb-kaemmerer.de"
              >
                info@stb-kaemmerer.de
              </a>
            </Typography>
          </Box>
        </Box>
        <Box
          my={{ xs: 2, sm: 0 }}
          p={3}
          className={classes.img}
          flexGrow={1}
          bgcolor={grey[200]}
          flexShrink={1}
          flexBasis={xs ? "auto" : "0px"}
        >
          {Boolean(success) ? successView : formView}
        </Box>
      </Box>
    </>
  );
};

export default Kontakt;
