import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Link } from "react-router-dom";
import PlusIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: "none",
    color: "white"
  },
  hoverZoom: {
    transition: "transform 0.15s ease-in",
    "&:hover": {
      transform: "scale(1.03)"
    }
  },
  plusIcon: {
    width: 50,
    height: 50
  },
  subtitle: {
    fontWeight: 500,
    fontSize: "1.6rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.4rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem"
    }
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "1.4rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.6rem"
    }
  },
  title: {
    fontSize: "1.8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.6rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.2rem"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3rem"
    }
  },
  bigSubtitle: {
    fontSize: "1.8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.6rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.1rem"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.6rem"
    }
  },
  icon: {
    width: 60,
    height: 60,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      width: 80,
      height: 80
    }
  }
}));

const KaBox = ({
  boxClasses,
  last,
  title,
  subtitle,
  subtitle2,
  showMore = true,
  showPlus = true,
  to = null,
  linkText = "Mehr erfahren",
  hoverZoom = true,
  light = false,
  align = "flex-start",
  icon = null
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();

  let usedIcon = null;
  if (icon) {
    usedIcon = React.cloneElement(icon, { className: classes.icon });
  }

  return (
    <Box
      className={clsx(boxClasses, classes.root, {
        [classes.hoverZoom]: hoverZoom
      })}
      component={to ? Link : undefined}
      to={to || undefined}
      height={xs ? 200 : "100%"}
      bgcolor={light ? theme.palette.primary.light : theme.palette.primary.main}
      display="flex"
      flexGrow={1}
      flexShrink={1}
      flexBasis={xs ? "auto" : "0px"}
      flexDirection="column"
      alignItems="center"
      mr={xs || last ? 0 : 2}
      mb={xs ? (last ? 0 : 2) : 0}
      width={xs ? "100%" : "auto"}
    >
      <Box
        width="100%"
        display="flex"
        flexGrow={1}
        alignItems="center"
        justifyContent={align}
        px={3}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={align}
          ml={title ? 0 : { xs: 0, sm: -4, md: -2 }}
        >
          {Boolean(usedIcon) && usedIcon}
          {Boolean(subtitle) && (
            <Typography
              align={align === "center" ? "center" : "left"}
              variant={title ? "h5" : "h4"}
              component="div"
              className={clsx(title ? classes.subtitle : classes.bigSubtitle)}
            >
              {subtitle}
            </Typography>
          )}
          {Boolean(subtitle2) && (
            <Typography
              align={align === "center" ? "center" : "left"}
              variant="h5"
              component="div"
              className={classes.subtitle2}
            >
              {subtitle2}
            </Typography>
          )}
          {Boolean(title) && (
            <Typography
              variant="h1"
              component="h4"
              className={classes.title}
              align={align === "center" ? "center" : "left"}
            >
              {title}
            </Typography>
          )}
        </Box>
      </Box>
      {(showMore || showPlus) && (
        <Box
          px={3}
          pb={2}
          width="100%"
          height={50}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          {showMore && <Typography variant="body2">{linkText} >></Typography>}
          {!showMore && <Typography variant="body2"></Typography>}
          {showPlus && <PlusIcon className={classes.plusIcon} />}
        </Box>
      )}
    </Box>
  );
};

export default KaBox;
