import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import amber from "@material-ui/core/colors/amber";
import blueGrey from "@material-ui/core/colors/blueGrey";
import grey from "@material-ui/core/colors/grey";
import { pink } from "@material-ui/core/colors";

export const THEME = responsiveFontSizes(
  createMuiTheme({
    shape: {
      borderRadius: 0
    },
    breakpoints: {
      values: {
        lg: 1280,
        md: 960,
        sm: 600,
        xl: 1560,
        xs: 0
      }
    },
    palette: {
      type: "light",
      primary: {
        light: "#aab0b3",
        main: "#8a8d85"
      },
      secondary: {
        main: "#f9ad49",
        dark: "#f0873c"
      },
      background: {
        default: "#dbdcda"
      }
    },
    typography: {
      fontFamily: "Roboto",
      h1: {
        fontSize: "3.2rem",
        fontWeight: "black"
      },
      h2: {
        fontSize: "3rem",
        fontWeight: "bold"
      },
      h3: {
        fontSize: "2.6rem",
        fontWeight: "black"
      },
      h4: {
        fontSize: "2.4rem"
      },
      h5: {
        fontSize: "1.8rem",
        fontWeight: "bold"
      },
      h6: {
        fontSize: "1.25rem",
        fontWeight: 500,
        lineHeight: 1.3
      },
      body1: {
        fontSize: "1.1rem",
        fontWeight: 500
      },
      body2: {
        fontSize: "1rem"
      },
      subtitle2: {
        fontWeight: "bold"
      },
      overline: {
        fontWeight: 500,
        fontSize: "0.9rem",
        textTransform: "none",
        lineHeight: 1.2
      }
    },
    overrides: {
      MuiButton: {
        root: {
          fontWeight: 500,
          textTransform: "none"
        }
      },
      MuiBadge: {
        badge: {
          fontWeight: 700
        }
      }
    },
    props: {
      MuiButton: {
        disableFocusRipple: true
      },
      MuiTab: {
        disableFocusRipple: true
      },
      MuiIconButton: {
        disableFocusRipple: true
      }
    }
  })
);
