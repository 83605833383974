import React from "react";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999999
  }
}));

const KaProgressBar = () => {
  const classes = useStyles();
  const showProgress = useSelector(state => state.feedback.showProgress);
  return (
    <>
      {showProgress > 0 && (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default KaProgressBar;
