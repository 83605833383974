import React from "react";
import { Box, useTheme, makeStyles } from "@material-ui/core";
import useScreensize from "../../hooks/useScreensize";
import KaBox from "./KaBox";
import clsx from "clsx";
import KaCite from "./KaCite";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  arrow: {
    width: 0,
    height: 0,
    borderTop: "50px solid transparent",
    borderRight: "70px solid red",
    borderRightColor: theme.palette.primary.main,
    borderBottom: "50px solid transparent",
    position: "absolute",
    right: "50%",
    top: 0,
    bottom: 0
  },
  blur: {
    filter: "blur(1.5rem)",
    borderRightColor: theme.palette.primary.dark
  },
  box: {
    zIndex: 9
  },
  img: {
    backgroundSize: "cover",
    backgroundPosition: "left center"
  },
  linkedBox: {
    textDecoration: "none"
  },
  hoverZoom: {
    transition: "transform 0.15s ease-in",
    "&:hover": {
      transform: "scale(1.018)"
    }
  }
}));

const KaCategoryHeader = ({
  linkText,
  title,
  subtitle,
  subtitle2,
  to = null,
  img,
  infoFootnote,
  infoContent,
  showMore = true,
  showPlus = true
}) => {
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();
  const classes = useStyles();

  let height = 220;
  if (md) {
    height = 240;
  }
  if (lg) {
    height = 260;
  }
  if (xl) {
    height = 300;
  }

  const arrowStyle = {
    borderTopWidth: height / 2,
    borderBottomWidth: height / 2
  };

  return (
    <>
      <Box display="flex" flexDirection={xs ? "column" : "row"}>
        <Box
          component={to ? Link : undefined}
          to={to || undefined}
          className={clsx(classes.linkedBox, { [classes.hoverZoom]: to })}
          position="relative"
          overflow="hidden"
          width={xs ? "100%" : "60%"}
          display="flex"
          height={xs ? undefined : height}
          mr={2}
          flexShrink={0}
          bgcolor={theme.palette.primary.dark}
          flexDirection={xs ? "column" : "row"}
          mb={{ xs: 2, sm: 0 }}
          mt={{ xs: 2, sm: 0 }}
        >
          <Box
            style={{ backgroundImage: "url(" + img + ")" }}
            className={classes.img}
            height={xs ? height : "100%"}
            width={xs ? "100%" : "50%"}
            flexShrink={0}
            bgcolor={theme.palette.primary.light}
          ></Box>
          <KaBox
            boxClasses={classes.box}
            last
            title={title}
            subtitle={subtitle}
            subtitle2={subtitle2}
            linkText={linkText}
            hoverZoom={false}
            showMore={showMore}
            showPlus={showPlus}
          />
          {!xs && (
            <>
              <div
                className={clsx(classes.blur, classes.arrow)}
                style={arrowStyle}
              />
              <div className={classes.arrow} style={arrowStyle} />
            </>
          )}
        </Box>
        <Box height={height} flexGrow={1} bgcolor={theme.palette.primary.main}>
          <KaCite content={infoContent} footnote={infoFootnote} />
        </Box>
      </Box>
    </>
  );
};

export default KaCategoryHeader;
