import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  IconButton,
  useMediaQuery,
  Box
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import KaBoxRow from "../shared/KaBoxRow";
import KaBox from "../shared/KaBox";
import useWindowSize from "../../hooks/useWindowSize";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KaMap from "../shared/KaMap";
import CloudIcon from "mdi-react/CloudDownloadOutlineIcon";
import MonitorIcon from "mdi-react/MonitorIcon";
import SwapIcon from "mdi-react/SwapHorizontalIcon";
import LinkIcon from "mdi-react/OpenInNewIcon";
import ServiceDatevUnternehmenOnline from "./ServiceDatevUnternehmenOnline";
import ServiceDatevUploadMobil from "./ServiceDatevUploadMobil";
import ServiceTeamViewer from "./ServiceTeamViewer";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  videoWrapper: {
    width: "100%",
    height: 0,
    paddingBottom: "66.6%",
    position: "relative"
  },
  video: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%"
  }
}));

const Service = ({ match }) => {
  const classes = useStyles();
  const [windowWidth, windowHeight] = useWindowSize();
  const [sliderHeight, setSliderHeight] = useState(0);
  const [currentDialog, setCurrentDialog] = useState(null);
  const [closed, setClosed] = useState(false);
  const dispatch = useDispatch();
  const currentSlideIndex = useSelector(state => state.slider.currentIndex);
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();
  const history = useHistory();

  useEffect(() => {
    if (!match.params.dialog) {
      setClosed(false);
    }
    setCurrentDialog(match.params.dialog || null);
  }, [match.params.dialog]);

  useEffect(() => {
    if (!xs) {
      const availableHeight = windowHeight - 80 - 252 - 200;
      setSliderHeight(availableHeight);
    } else {
      setSliderHeight(240);
    }
  }, [windowHeight, windowWidth]);

  const onPrev = () => {
    dispatch({ type: "SET_PREV_SLIDE" });
  };

  const onNext = () => {
    dispatch({ type: "SET_NEXT_SLIDE" });
  };

  const onUpdateSlider = event => {
    // console.log(event);
  };

  if (closed) {
    setTimeout(() => {
      history.push("/service");
    }, 100);
    // return <Redirect to="/service" push />;
  }

  return (
    <>
      <KaBoxRow size="large" last>
        <KaBox
          icon={<MonitorIcon />}
          subtitle={<strong>DATEV</strong>}
          subtitle2="Unternehmen online"
          to="/service/datev-unternehmen-online"
          showMore={false}
          align="center"
        ></KaBox>
        <KaBox
          icon={<CloudIcon />}
          subtitle={<strong>DATEV</strong>}
          subtitle2="Upload mobil"
          to="/service/datev-upload-mobil"
          showMore={false}
          align="center"
        ></KaBox>
        <KaBox
          icon={<SwapIcon />}
          subtitle={<strong>TeamViewer</strong>}
          to="/service/teamviewer"
          showMore={false}
          align="center"
        ></KaBox>
      </KaBoxRow>

      <ServiceDatevUnternehmenOnline
        open={currentDialog === "datev-unternehmen-online"}
        close={() => {
          setClosed(true);
        }}
      />
      <ServiceDatevUploadMobil
        open={currentDialog === "datev-upload-mobil"}
        close={() => {
          setClosed(true);
        }}
      />

      <ServiceTeamViewer
        open={currentDialog === "teamviewer"}
        close={() => {
          setClosed(true);
        }}
      />



      <KaMap />
    </>
  );
};

export default Service;
