import React from "react";
import { Box, makeStyles, Typography, useTheme } from "@material-ui/core";
import Ausrufezeichen from "../../assets/ausrufezeichen.png";

const useStyles = makeStyles(theme => ({
  infoBox: {
    backgroundImage: "url(" + Ausrufezeichen + ")",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 80%"
  },
  footnote: {
    width: "100%",
    textAlign: "right",
    paddingRight: theme.spacing(1)
  }
}));

const KaCite = ({ height = "100%", width = "100%", content, footnote }) => {
  const classes = useStyles();

  const theme = useTheme();

  return (
    <>
      <Box
        color="white"
        className={classes.infoBox}
        height={height}
        width={width}
        bgcolor={theme.palette.secondary.dark}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="stretch"
        p={1}
      >
        <Box
          flexGrow={1}
          display="flex"
          alignContent="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography variant="h6" component="div" align="center">
            {content}
          </Typography>
        </Box>

        {Boolean(footnote) && (
          <Typography variant="overline" className={classes.footnote}>
            {footnote}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default KaCite;
