import React, { useState } from "react";
import KaDialog from "../shared/KaDialog";
import Img1 from "../../assets/wir-suchen_1.jpg";
import Img2 from "../../assets/wir-suchen_2.jpg";
import Download1 from "../../assets/Steuerberater_Handout.pdf";
import Download2 from "../../assets/Steuerfachwirt_Handout.pdf";
import {
  Box,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Divider,
  TextField,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";
import { grey, green } from "@material-ui/core/colors";
import API from "../../utils/API";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles(theme => ({
  img: {
    width: "100%",
    height: "auto",
    display: "block"
  },
  imgLink: {
    width: "100%",
    display: "block",
  },
  paragraph: {
    margin: theme.spacing(3, 0)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  dividerTransp: {
    height: 0,
    margin: theme.spacing(0.5, 0)
  },
  link: {
    color: "inherit",
    fontWeight: "bold",
    textDecoration: "none"
  },
  check: {
    color: green[500],
    width: 40,
    height: 40
  }
}));



const KanzleiKarriere = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();



  return (
    <>
      <KaDialog open={open} close={close}>
        <>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            p={{ xs: 0, sm: 3 }}
            height={xs ? undefined : sm ? 780 : 700}
          >
            <Box
              display="flex"
              flexDirection="column"
              color="white"
              mr={{ xs: 0, sm: 3 }}
              px={{ xs: 3, sm: 8 }}
              flexGrow={1}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
            >
               <a href={Download1} className={classes.imgLink} target="_blank" rel="noopener noreferrer">
                <img src={Img1} className={classes.img} alt="Wir suchen eine/n Steuerberater/in" />
               </a>
               <a href={Download2} className={classes.imgLink} target="_blank" rel="noopener noreferrer">
                <img src={Img2} className={classes.img} alt="Wir suchen eine/n Steuerfachangestellte/n / Steuerfachwirt/in" />
               </a>
            </Box>

          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default KanzleiKarriere;
