import { combineReducers } from "redux";
import feedbackReducer from "./feedbackReducer";
import sliderReducer from "./sliderReducer";

const rootReducer = combineReducers({
  feedback: feedbackReducer,
  slider: sliderReducer
});

export default rootReducer;
