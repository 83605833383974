import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

const KaTaetigkeitsfeld = ({ children, last = false }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        width="100%"
        p={2}
        height={80}
        display="flex"
        alignItems="center"
        color="white"
        bgcolor={theme.palette.primary.main}
        mb={last ? 0 : 1}
      >
        <Typography variant="body2" component="div">
          {children}
        </Typography>
      </Box>
    </>
  );
};

export default KaTaetigkeitsfeld;
