import React from "react";
import KaDialog from "../shared/KaDialog";
import Img from "../../assets/generation-z.jpg";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KaCite from "../shared/KaCite";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "cover",
    backgroundPosition: "right center"
  },
  paragraph: {
    margin: theme.spacing(2, 0),
    lineHeight: 1.3
  },
  chevron: {
    marginRight: theme.spacing(1)
  }
}));

const SpektrumGenerationZ = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();

  const infoContent = (
    <>
      Wir können den Wind nicht ändern,
      <br />
      aber die Segel <strong>neu setzen</strong>.
    </>
  );

  return (
    <>
      <KaDialog open={open} close={close}>
        <>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            p={{ sm: 3 }}
            height={xs ? undefined : sm ? 780 : 700}
          >
            <Box
              color="white"
              p={{ xs: 3, sm: 4 }}
              mr={{ sm: 3 }}
              flexGrow={1}
              bgcolor={theme.palette.primary.main}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
            >
              <Typography variant="h1" component="h2">
                Generation Z
              </Typography>
              <Typography variant="h6" component="h3" gutterBottom>
                Die digitale Kanzlei – Immer einen Schritt voraus
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Die Zukunft wird digital. So sagte man vor einigen Jahren.
                Längst sind wir dort angekommen. Das wollen wir nicht mehr
                ändern – aber wir können unser Handeln danach ausrichten.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Digitalisierung schafft Freiräume für eine noch aktivere und
                persönlichere Zusammenarbeit.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                So beispielsweise auch bei der digitalen Buchhaltung.
              </Typography>
              <Typography
                variant="body1"
                component="div"
                className={classes.paragraph}
              >
                <>
                  <Box display="flex">
                    <ChevronRightIcon className={classes.chevron} />
                    <span>Kostenersparnis</span>
                  </Box>
                  <Box display="flex">
                    <ChevronRightIcon className={classes.chevron} />
                    <span>Zeitersparnis</span>
                  </Box>
                  <Box display="flex">
                    <ChevronRightIcon className={classes.chevron} />
                    <span>Größere Produktivität</span>
                  </Box>
                  <Box display="flex">
                    <ChevronRightIcon className={classes.chevron} />
                    <span>Kurze Reaktionszeiten</span>
                  </Box>
                  <Box display="flex">
                    <ChevronRightIcon className={classes.chevron} />
                    <span>Kurzfristige Auswertungen</span>
                  </Box>
                </>
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Gerne finden wir mit Ihnen persönlich heraus, wie Sie die
                Vorteile der „Digitalen Kanzlei“ nutzen können.
              </Typography>
            </Box>
            <Box
              flexGrow={1}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
              display="flex"
              flexDirection="column"
            >
              <Box
                className={classes.img}
                style={{ backgroundImage: "url(" + Img + ")" }}
                flexGrow={1}
              ></Box>
              <Box
                height={240}
                flexShrink={0}
                bgcolor={theme.palette.primary.main}
                mt={3}
              >
                <KaCite content={infoContent} footnote="Aristoteles" />
              </Box>
            </Box>
          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default SpektrumGenerationZ;
