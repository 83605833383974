import React from "react";
import KaDialog from "../shared/KaDialog";
import Img from "../../assets/unternehmen-online.jpg";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "cover",
    backgroundPosition: "left center"
  },
  paragraph: {
    margin: theme.spacing(3, 0)
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none"
  }
}));

const ServiceDatevUnternehmenOnline = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();

  return (
    <>
      <KaDialog title="DATEV Unternehmen online" open={open} close={close}>
        <>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            p={{ xs: 2, sm: 3 }}
            height={xs ? undefined : sm ? 680 : 600}
          >
            <Box
              color="white"
              p={{ xs: 3, sm: 4 }}
              mr={{ sm: 3 }}
              bgcolor={theme.palette.primary.main}
              flexShrink={0}
              width={xs ? "100%" : "65%"}
            >
              <Typography variant="h6" component="h3" gutterBottom>
                <strong>
                  Digitale Zusammenarbeit mit Ihrem Steuerberater – ganz nach
                  Ihrem Bedarf
                </strong>
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Profitieren auch Sie von den Vorteilen der Digitalisierung in
                Ihrem Unternehmen – mit DATEV Unternehmen online.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Mit dieser DATEV-Lösung arbeiten Sie schnell und sicher mit
                Ihrem Steuerberater zusammen, abgestimmt auf Ihre Anforderungen.
                Das gilt sowohl für die Finanzbuchführung, als auch für die
                Lohnabrechnung.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                So wird vieles einfacher und effizienter.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Mehr erfahren:
                <br />
                <a
                  className={classes.link}
                  href="https://www.datev.de/web/de/datev-shop/komplettloesungen/datev-unternehmen-online/"
                  target="_blank"
                >
                  www.datev.de/web/de/datev-shop/komplettloesungen/datev-unternehmen-online/
                </a>
              </Typography>
            </Box>
            <Box
              p={3}
              className={classes.img}
              style={{ backgroundImage: "url(" + Img + ")" }}
              flexGrow={1}
              bgcolor={theme.palette.primary.main}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
              height={xs ? 400 : undefined}
            ></Box>
          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default ServiceDatevUnternehmenOnline;
