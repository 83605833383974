import React from "react";
import KaDialog from "../shared/KaDialog";
import KaDialogContentMainImageInfo from "../shared/KaDialogContentMainImageInfo";
import Img from "../../assets/img_kaemmerer.jpg";
import { Box, Typography, makeStyles } from "@material-ui/core";

const listItems = [
  "Sie informieren uns im Vorfeld über Projekte und unternehmerische Vorhaben",
  "Sie sind offen für neue Ideen",
  "Honorarzahlung innerhalb der Frist bzw. Lastschriftverfahren",
  "Rechtzeitige und vollständige Einreichung von angeforderten Unterlagen",
  "Respektvoller Umgang mit allen Mitarbeitern",
  "Sie planen Ihren unternehmerischen Erfolg und wollen vorwärts kommen",
  "Sie empfehlen uns weiter"
];

const useStyles = makeStyles(theme => ({
  number: {
    marginRight: theme.spacing(2)
  },
  listText: {
    width: "95%"
  }
}));

const HomeUnsereErwartungen = ({ open, close }) => {
  const classes = useStyles();
  const mainContent = (
    <Box width="100%">
      {listItems.map((item, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="baseline"
          my={1}
          maxWidth="100%"
        >
          <Typography variant="h5" component="div" className={classes.number}>
            {index + 1 + "."}
          </Typography>
          <Typography variant="body1" className={classes.listText}>
            {item}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  const infoContent = (
    <>
      Die Basis der
      <br />
      Mandantenbeziehung
      <br />
      ist <strong>Vertrauen</strong>.<br />
      Vertrauen kommt von <strong>Verlässlichkeit</strong>.
    </>
  );

  return (
    <>
      <KaDialog title="Unsere Erwartungen" open={open} close={close}>
        <KaDialogContentMainImageInfo
          mainContent={mainContent}
          image={Img}
          infoContent={infoContent}
        />
      </KaDialog>
    </>
  );
};

export default HomeUnsereErwartungen;
