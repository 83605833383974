import React, { useEffect } from "react";
import Home from "./components/pages/Home";
import { ThemeProvider } from "@material-ui/styles";
import * as constants from "./constants";
import { CssBaseline } from "@material-ui/core";
import Layout from "./components/Layout";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Kanzlei from "./components/pages/Kanzlei";
import Spektrum from "./components/pages/Spektrum";
import Service from "./components/pages/Service";
import Kontakt from "./components/pages/Kontakt";
import Impressum from "./components/pages/Impressum";
import Datenschutz from "./components/pages/Datenschutz";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={constants.THEME}>
        <CssBaseline />
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/home/:dialog" component={Home} />
            <Route exact path="/kanzlei" component={Kanzlei} />
            <Route path="/kanzlei/:dialog" component={Kanzlei} />
            <Route exact path="/spektrum" component={Spektrum} />
            <Route path="/spektrum/:dialog" component={Spektrum} />
            <Route exact path="/service" component={Service} />
            <Route path="/service/:dialog" component={Service} />
            <Route path="/impressum" component={Impressum} />
            <Route path="/datenschutz" component={Datenschutz} />
            <Route path="/kontakt" component={Kontakt} />
          </Switch>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
