import React from "react";
import KaDialog from "../shared/KaDialog";
import KaDialogContentMainImageInfo from "../shared/KaDialogContentMainImageInfo";
import Img from "../../assets/img_gemeinsamer_weg.jpg";
import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  number: {
    marginRight: theme.spacing(2)
  },
  listText: {
    width: "95%"
  },
  headline: {
    fontWeight: 500
  },
  subhead: {
    marginTop: theme.spacing(4),
    fontWeight: 500
  },
  phone: {}
}));

const HomeGemeinsamerWeg = ({ open, close }) => {
  const classes = useStyles();
  const mainContent = (
    <Box>
      <Typography variant="h3" className={classes.headline}>
        Ihre und unsere Erwartungen decken sich?
      </Typography>
      <Typography variant="h5" component="p" className={classes.subhead}>
        Dann sollten wir uns kennenlernen.
      </Typography>
      <Typography variant="h5" component="p" className={classes.subhead}>
        Jetzt Kontakt aufnehmen
      </Typography>
      <Typography variant="h1" className={classes.phone}>
        0631/2057760
      </Typography>
    </Box>
  );

  const infoContent = (
    <>
      Zusammenkommen ist ein <strong>Beginn</strong>,<br />
      Zusammenbleiben ein <strong>Fortschritt</strong>,<br />
      Zusammenarbeiten ein <strong>Erfolg</strong>.
    </>
  );

  return (
    <>
      <KaDialog title="Gemeinsamer Weg" open={open} close={close}>
        <KaDialogContentMainImageInfo
          mainContent={mainContent}
          image={Img}
          infoContent={infoContent}
          infoFootnote="Henry Ford"
        />
      </KaDialog>
    </>
  );
};

export default HomeGemeinsamerWeg;
