import React from "react";
import { Typography, makeStyles, Box, Grid } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3)
  },
  paragraph: {
    marginBottom: theme.spacing(3),
    fontSize: "0.9rem",
    fontWeight: "normal"
  },
  paragraph2: {
    marginBottom: theme.spacing(2),
    fontSize: "0.8rem",
    fontWeight: "normal",
    color: grey[700]
  }
}));

const Impressum = () => {
  const classes = useStyles();
  return (
    <Box pt={{ xs: 2, md: 8 }} pb={2} px={{ sm: 3, lg: 8 }}>
      <Typography variant="h1" className={classes.title}>
        Impressum
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={5}>
          <Typography variant="body1" className={classes.paragraph}>
            Dipl.-Betriebswirt (FH)
            <br />
            Steffen Kämmerer
            <br />
            Steuerberater
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            Burgstraße 39
            <br />
            67659 Kaiserslautern
            <br />
            Telefon: 0631 / 205776-0
            <br />
            Telefax: 0631 / 205776-99
            <br />
            E-Mail: info@stb-kaemmerer.de
            <br />
            www.stb-kaemmerer.de
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            USt.-IdNr.: DE207981351
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            Verantwortlich i.S.v. §6 MDStV: Steffen Kämmerer
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="body1" className={classes.paragraph2}>
            Die gesetzliche Berufsbezeichnung "Steuerberater" wurde Herrn
            Steffen Kämmerer in der Bundesrepublik Deutschland, Rheinland-Pfalz,
            verliehen. Herr Steffen Kämmerer gehört der Steuerberaterkammer
            Rheinland-Pfalz an.
          </Typography>
          <Typography variant="body1" className={classes.paragraph2}>
            Der Berufsstand der Steuerberater unterliegt folgenden
            berufsrechtlichen Regeln:
          </Typography>
          <Typography
            variant="body1"
            component="div"
            className={classes.paragraph2}
          >
            <ul>
              <li>Steuerberatungsgesetz (StBerG)</li>
              <li>Durchfühungsverordnung zum Steuerberatungsgesetz (DVStB)</li>
              <li>Berufsordnung (BOStB)</li>
              <li>Steuerberatervergütungsverordnung (StBVV)</li>
            </ul>
          </Typography>
          <Typography variant="body1" className={classes.paragraph2}>
            Die vorstehenden Regelungen können unter der Homepage der
            zuständigen Bundessteuerberaterkammer eingesehen werden.
          </Typography>
          <Typography variant="body1" className={classes.paragraph2}>
            Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG):
            <br />
            Information nach dem Verbraucherstreitbeilegungsgesetz: Es besteht
            keine Verpflichtung und keine Bereitschaft zur Teilnahme an einem
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Impressum;
