import React from "react";
import KaDialog from "../shared/KaDialog";
import Img from "../../assets/teamviewer.jpg";
import ImgTeamViewer from "../../assets/teamviewer_logo.png";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "cover",
    backgroundPosition: "left center"
  },
  paragraph: {
    margin: theme.spacing(3, 0)
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none"
  },
  teamViewer: {
    height: 60,
    width: "auto"
  }
}));

const ServiceTeamViewer = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();

  return (
    <>
      <KaDialog title="TeamViewer" open={open} close={close}>
        <>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            p={{ sm: 3 }}
            height={xs ? undefined : sm ? 680 : 600}
          >
            <Box
              color="white"
              p={{ xs: 3, sm: 4 }}
              mr={{ sm: 3 }}
              bgcolor={theme.palette.primary.main}
              flexShrink={0}
              width="65%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6" component="h3" gutterBottom>
                  <strong>Mit TeamViewer können Sie...</strong>
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  ...einfach, schnell und sicher auf PCs, Macs und
                  Linux-Computer aus der Ferne zugreifen. Auf über 200.000.000
                  Computern ist TeamViewer bereits im Einsatz.
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.paragraph}
                >
                  <>
                    Vorteile:
                    <Box display="flex">
                      <ChevronRightIcon className={classes.chevron} />
                      <span>
                        Entfernte Computer steuern, als säßen Sie direkt davor
                      </span>
                    </Box>
                    <Box display="flex">
                      <ChevronRightIcon className={classes.chevron} />
                      <span>
                        Ihren Kunden, Kollegen und Freunden von unterwegs
                        Support geben
                      </span>
                    </Box>
                    <Box display="flex">
                      <ChevronRightIcon className={classes.chevron} />
                      <span>
                        Auf Ihren Arbeitsplatz-PC mit allen Dokumenten und
                        installierten Anwendungen zugreifen
                      </span>
                    </Box>
                    <Box display="flex">
                      <ChevronRightIcon className={classes.chevron} />
                      <span>
                        Unbeaufsichtigte Computer (z.B. Server) aus der Ferne
                        administrieren
                      </span>
                    </Box>
                  </>
                </Typography>
              </div>
              <div>
                <a href="https://www.teamviewer.com/de/" target="_blank">
                  <img
                    className={classes.teamViewer}
                    src={ImgTeamViewer}
                    alt="TeamViewer"
                  />
                </a>
              </div>
            </Box>
            <Box
              p={3}
              className={classes.img}
              style={{ backgroundImage: "url(" + Img + ")" }}
              flexGrow={1}
              bgcolor={theme.palette.primary.main}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
              height={xs ? 400 : undefined}
            ></Box>
          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default ServiceTeamViewer;
