import React from "react";
import { Box, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({}));

const KaBoxRow = ({ last, children, first = false, size = "normal" }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();

  let height = 160;
  if (md) {
    height = 180;
  }
  if (lg) {
    height = 220;
  }
  if (xl) {
    height = 220;
  }

  let boxHeight = height + theme.spacing(2);
  if (last && !first) {
    boxHeight = height + theme.spacing(4);
  }
  if (!last && first) {
    boxHeight = height;
  }

  if (size === "large") {
    boxHeight += 45;
  }

  return (
    <Box
      display="flex"
      flexDirection={xs ? "column" : "row"}
      height={xs ? "auto" : boxHeight}
      alignItems="center"
      pt={first ? 0 : 2}
      pb={last ? 2 : 0}
    >
      {children}
    </Box>
  );
};

export default KaBoxRow;
