import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";
import LogoIcon from "../../assets/logo_icon.png";

const useStyles = makeStyles(theme => ({
  footerTxt: {
    fontSize: "0.9rem"
  },
  link: {
    color: "inherit",
    textDecoration: "none"
  },
  logo: {
    height: 80,
    width: "auto",
    position: "relative",
    top: -10
  }
}));

const KaFooter = () => {
  const { xs, sm, md, lg, xl } = useScreensize();
  const classes = useStyles();

  const footerNav = (
    <>
      <Typography variant="body2" className={classes.footerTxt}>
        <Link className={classes.link} to="/impressum">
          Impressum
        </Link>
        {" | "}
        <Link className={classes.link} to="/datenschutz">
          Datenschutz
        </Link>
      </Typography>
    </>
  );

  return (
    <>
      {!sm && (
        <Box mb={xs ? 2 : 0}>
          <Grid container spacing={xs ? 2 : 3}>
            <Grid item xs={12} sm={8}></Grid>
            <Grid item xs={12} sm={4}>
              {footerNav}
            </Grid>
          </Grid>
        </Box>
      )}
      <Grid container spacing={xs ? 2 : 3}>
        <Grid item xs={9} sm={3} md={2}>
          <Typography variant="body2" className={classes.footerTxt}>
            Steffen Kämmerer
            <br />
            Steuerberater
          </Typography>
        </Grid>
        <Grid item xs={3} sm={1}>
          <img src={LogoIcon} className={classes.logo} alt="" />
        </Grid>
        {sm && (
          <>
            <Grid item sm={3} />
            <Grid item sm={5}>
              {footerNav}
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={3} md={2}>
          <Typography variant="body2" className={classes.footerTxt}>
            Burgstraße 39
            <br />
            67659 Kaiserslautern
            <br />
            Deutschland
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="body2" className={classes.footerTxt}>
            Telefon: 0631/2057760
            <br />
            Telefax: 0631/20577699
            <br />
            E-Mail:{" "}
            <a className={classes.link} href="mailto:info@stb-kaemmerer.de">
              info@stb-kaemmerer.de
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <Typography variant="body2" className={classes.footerTxt}>
            Bürozeiten:
            <br />
            Mo - Do, 08:30 bis 12:00 Uhr und 13:30 Uhr bis 16:00 Uhr
            <br />
            Fr 08:30 bis 12:00 Uhr
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default KaFooter;
