import React from "react";
import KaDialog from "../shared/KaDialog";
import Img from "../../assets/upload-mobil.jpg";
import ImgGooglePlay from "../../assets/google-play.png";
import ImgAppStore from "../../assets/app-store.png";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "auto 100%",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat"
  },
  paragraph: {
    margin: theme.spacing(3, 0)
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none"
  },
  googlePlay: {
    height: 60,
    width: "auto",
    marginRight: theme.spacing(3)
  },
  appStore: {
    height: 60,
    width: "auto"
  }
}));

const ServiceDatevUploadMobil = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();

  return (
    <>
      <KaDialog title="DATEV Upload mobil" open={open} close={close}>
        <>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            p={{ xs: 2, sm: 3 }}
            height={xs ? undefined : sm ? 680 : 600}
          >
            <Box
              color="white"
              p={{ xs: 3, sm: 4 }}
              mr={{ sm: 3 }}
              bgcolor={theme.palette.primary.main}
              flexShrink={0}
              width={xs ? "100%" : "65%"}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6" component="h3" gutterBottom>
                  <strong>
                    Machen Sie Ihr mobiles Endgerät zum Scanner und
                    digitalisieren Sie schnell und einfach Ihre Belege und
                    Dokumente.
                  </strong>
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  Übertragen Sie diese im Anschluss nach DATEV Unternehmen
                  online (Belege online) jederzeit und an jedem Ort.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  Damit bieten wir einen alternativen Weg für die
                  Belegeinreichung mit hohem Anwenderkomfort, d.h.
                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                  <strong>einfach – sicher – innovativ</strong>
                </Typography>
              </div>
              <div>
                <Typography variant="body1" className={classes.paragraph}>
                  Mehr erfahren:
                </Typography>

                <Box display="flex" flexDirection={xs ? "column" : "row"}>
                  <a
                    href="https://play.google.com/store/apps/details?id=de.datev.belegtransfer&hl=de"
                    target="_blank"
                  >
                    <img
                      className={classes.googlePlay}
                      src={ImgGooglePlay}
                      alt="Google Play"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/de/app/datev-upload-mobil/id1079321342"
                    target="_blank"
                  >
                    <img
                      className={classes.appStore}
                      src={ImgAppStore}
                      alt="Apple App Store"
                    />
                  </a>
                </Box>
              </div>
            </Box>
            <Box
              mt={xs ? 1 : 0}
              mb={xs ? 1 : 0}
              p={3}
              className={classes.img}
              style={{ backgroundImage: "url(" + Img + ")" }}
              flexGrow={1}
              bgcolor="white"
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
              height={xs ? 400 : undefined}
            ></Box>
          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default ServiceDatevUploadMobil;
