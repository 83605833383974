import React from "react";
import KaDialog from "../shared/KaDialog";
import Img from "../../assets/kanzlei_team.jpg";
import Img2 from "../../assets/img_kaemmerer.jpg";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "cover"
  }
}));

const KanzleiTeam = ({ open, close }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();

  return (
    <>
      <KaDialog open={open} close={close}>
        <>
          <Box
            style={{ backgroundImage: "url(" + Img + ")" }}
            height={xs ? 140 : 300}
            className={classes.img}
          ></Box>
          <Box
            height={xs ? undefined : 112}
            display="flex"
            flexDirection={xs ? "column" : "row"}
            alignItems={xs ? "flex-start" : "center"}
            bgcolor={theme.palette.primary.main}
            color="white"
            p={3}
          >
            <Box flexGrow={1} flexShrink={0} pr={{ sm: 4 }}>
              <Typography variant="h1" component="h2">
                Unser Team
              </Typography>
            </Box>

            <Box flexShrink={1}>
              <Typography variant="body2">
                setzt sich zusammen aus erfahrenen, qualifizierten
                Mitarbeiterinnen und Mitarbeitern und einer Struktur, die eine
                umfassende Mandantenbetreuung auf hohem, fachlichen Niveau
                ermöglicht.
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            height={xs ? undefined : 300}
            p={3}
          >
            <Box
              className={classes.img}
              height={xs ? 240 : "100%"}
              width={xs ? "100%" : "40%"}
              style={{ backgroundImage: "url(" + Img2 + ")" }}
            ></Box>
            <Box
              display="flex"
              alignItems="center"
              p={3}
              color="white"
              height={xs ? undefined : "100%"}
              width={xs ? undefined : "60%"}
              bgcolor={theme.palette.primary.light}
            >
              <Box>
                <Typography variant="h1" component="h3" gutterBottom>
                  Steffen Kämmerer
                </Typography>
                <Typography variant="body2">
                  „Meine berufliche Laufbahn begann mit der Ausbildung zum
                  Steuerfachgehilfen. Nach Abschluss des Studiums der
                  Betriebswirtschaft - Fachrichtung Steuerwesen - in Worms, war
                  ich in verschiedenen Bereichen der Steuerberatung tätig. Mit
                  der Bestellung zum Steuerberater gründete ich im Jahr 2000
                  meine eigene Kanzlei in Kaiserslautern, um fortan noch
                  individueller und persönlicher Mandanten beraten zu können.“
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default KanzleiTeam;
