import React from "react";
import KaDialog from "../shared/KaDialog";
import KaDialogContentMainImageInfo from "../shared/KaDialogContentMainImageInfo";
import Img from "../../assets/img_ihre_erwartungen.jpg";
import { Box, Typography, makeStyles } from "@material-ui/core";

const listItems = [
  "Aktiv informieren über steuerlich und betriebswirtschaftlich wichtige Änderungen",
  "Kreative Steuer-Spartipps",
  "Leichte Erreichbarkeit und kurzfristige Terminvereinbarungen",
  "Fristgerechte Bearbeitung Ihrer Unterlagen und Zuverlässigkeit bei Terminabsprachen",
  "Freundlicher und wertschätzender Umgang sowie Kommunikation auf Augenhöhe",
  "Berücksichtigung Ihrer individuellen Ziele und Vorstellungen",
  "Rundum Zufriedenheit",
  "Faires Honorar mit Mehrwert"
];

const useStyles = makeStyles(theme => ({
  number: {
    marginRight: theme.spacing(2)
  },
  listText: {
    width: "95%"
  }
}));

const HomeIhreErwartungen = ({ open, close }) => {
  const classes = useStyles();
  const mainContent = (
    <Box width="100%">
      {listItems.map((item, index) => (
        <Box key={index} display="flex" alignItems="baseline" my={1}>
          <Typography variant="h5" component="div" className={classes.number}>
            {index + 1 + "."}
          </Typography>
          <Typography variant="body1" className={classes.listText}>
            {item}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  const infoContent = (
    <>
      Die Basis der
      <br />
      Mandantenbeziehung
      <br />
      ist <strong>Vertrauen</strong>.<br />
      Vertrauen kommt von <strong>Verlässlichkeit</strong>.
    </>
  );

  return (
    <>
      <KaDialog title="Ihre Erwartungen" open={open} close={close}>
        <KaDialogContentMainImageInfo
          mainContent={mainContent}
          image={Img}
          infoContent={infoContent}
        />
      </KaDialog>
    </>
  );
};

export default HomeIhreErwartungen;
