import React from "react";
import KaDialog from "../shared/KaDialog";
import Img from "../../assets/siegel2022.png";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "90% auto",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat"
  },
  paragraph: {
    margin: theme.spacing(3, 0)
  }
}));

const SpektrumSteuerberatung = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();

  return (
    <>
      <KaDialog open={open} close={close}>
        <>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            p={{ sm: 3 }}
            height={xs ? undefined : sm ? 780 : 700}
          >
            <Box
              color="white"
              p={{ xs: 3, sm: 4 }}
              mr={{ sm: 3 }}
              flexGrow={1}
              bgcolor={theme.palette.primary.main}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
            >
              <Typography variant="h1" component="h2">
                Steuerberatung
              </Typography>
              <Typography variant="h6" component="h3" gutterBottom>
                Tradition & Zukunft
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Wir beraten Mandanten von Einzelpersonen bis zu
                mittelständischen Unternehmen.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Sie alle vereint der Anspruch auf ein Maximum an individueller
                Beratung.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Unsere Kanzlei im Herzen von Kaiserslautern ist gleichermaßen
                Ansprechpartner für die Unternehmen der digitalen Zukunft als
                auch für traditionsreiche Handwerksbetriebe.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Nicht zuletzt durch unseren Sitz in der Kreishandwerkerschaft
                haben wir auch räumlich die notwendige Nähe zu unseren
                Mandanten.
              </Typography>
            </Box>
            <Box
              p={3}
              className={classes.img}
              style={{ backgroundImage: "url(" + Img + ")" }}
              flexGrow={1}
              bgcolor={theme.palette.primary.light}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
              height={xs ? 400 : undefined}
            ></Box>
          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default SpektrumSteuerberatung;
