const initState = {
  showProgress: 0,
  snackbar: { message: null, variant: null }
};

const feedbackReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_SNACKBAR":
      return {
        ...state,
        snackbar: { message: action.message, variant: action.variant }
      };
    case "RESET_SNACKBAR":
      return {
        ...state,
        snackbar: { message: null, variant: state.snackbar.variant }
      };
    case "SHOW_PROGRESS":
      return {
        ...state,
        showProgress: state.showProgress + 1
      };
    case "HIDE_PROGRESS":
      return {
        ...state,
        showProgress: state.showProgress - 1
      };
    default:
      return state;
  }
};

export default feedbackReducer;
