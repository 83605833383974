import React from "react";
import KaDialog from "../shared/KaDialog";
import Img from "../../assets/flexibilitaet.jpg";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "cover",
    backgroundPosition: "right center"
  },
  paragraph: {
    margin: theme.spacing(3, 0)
  }
}));

const KanzleiFlexibilitaet = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();

  return (
    <>
      <KaDialog open={open} close={close}>
        <>
          <Box
            display="flex"
            flexDirection={xs ? "column" : "row"}
            p={{ sm: 3 }}
            height={xs ? undefined : sm ? 780 : 700}
          >
            <Box
              color="white"
              p={{ xs: 3, sm: 4 }}
              mr={{ sm: 3 }}
              flexGrow={1}
              bgcolor={theme.palette.primary.main}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
            >
              <Typography variant="h1" component="h2">
                Flexibilität
              </Typography>
              <Typography variant="h6" component="h3" gutterBottom>
                Wir halten Ihnen den Rücken frei
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Die steuerrechtlichen Arbeitsbereiche werden immer komplexer,
                die Märkte schneller und internationaler.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Mit modernen Informationstechnologien arbeiten wir
                teamorientiert und rationell und bieten Ihnen auch bei
                ausgefallenen Problemen „maßgeschneiderte“ Lösungen.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                Wir setzen alles daran, wirtschaftliche Zusammenhänge,
                betriebliche Notwendigkeiten und steuerliche Erfordernisse
                miteinander zu verknüpfen.
              </Typography>
            </Box>
            <Box
              p={3}
              className={classes.img}
              style={{ backgroundImage: "url(" + Img + ")" }}
              flexGrow={1}
              bgcolor={theme.palette.primary.main}
              flexShrink={1}
              flexBasis={xs ? "auto" : "0px"}
              height={xs ? 400 : undefined}
            ></Box>
          </Box>
        </>
      </KaDialog>
    </>
  );
};

export default KanzleiFlexibilitaet;
