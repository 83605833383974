import React from "react";
import KaDialog from "../shared/KaDialog";
import KaDialogContentMainImageInfo from "../shared/KaDialogContentMainImageInfo";
import Img from "../../assets/taetigkeitsfelder.jpg";
import ImgTaetigkeit from "../../assets/taetigkeitsfelder.png";
import { Box, Typography, makeStyles, useTheme } from "@material-ui/core";
import useScreensize from "../../hooks/useScreensize";
import KaTaetigkeitsfeld from "../shared/KaTaetigkeitsfeld";
import KaCite from "../shared/KaCite";

const listItems = [
  "Aktiv informieren über steuerlich und betriebswirtschaftlich wichtige Änderungen",
  "Kreative Steuer-Spartipps",
  "Leichte Erreichbarkeit und kurzfristige Terminvereinbarungen",
  "Fristgerechte Bearbeitung Ihrer Unterlagen und Zuverlässigkeit bei Terminabsprachen",
  "Freundlicher und wertschätzender Umgang sowie Kommunikation auf Augenhöhe",
  "Berücksichtigung Ihrer individuellen Ziele und Vorstellungen",
  "Rundum Zufriedenheit",
  "Faires Honorar mit Mehrwert"
];

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  number: {
    marginRight: theme.spacing(2)
  },
  listText: {
    width: "95%"
  }
}));

const SpektrumTaetigkeitsfelder = ({ open, close }) => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const theme = useTheme();

  const infoContent = (
    <>
      <strong>Qualität</strong> zum Nutzen
      <br />
      unserer <strong>Mandanten</strong>
      <br />
      ist die Basis
      <br />
      für unser Geschäft
    </>
  );

  const contentDesk = (
    <div style={{ padding: "16px" }}>
      <img
        style={{ width: "100%", height: "auto" }}
        src={ImgTaetigkeit}
        alt="Tätigkeitsfelder"
      />
    </div>
  );

  const contentMobile = (
    <>
      <Box
        display="flex"
        flexDirection="column"
        p={{ sm: 2 }}
        height={xs ? undefined : sm ? 780 : 700}
        overflow="auto"
      >
        <Box width="100%" display="flex" flexDirection={xs ? "column" : "row"}>
          <Box
            mb={xs ? 1 : 0}
            mt={xs ? 1 : 0}
            width={xs ? "100%" : "60%"}
            flexShrink={0}
            height={xs ? 180 : 80 * 3 + 2 * theme.spacing(1)}
            mr={1}
            bgcolor={theme.palette.primary.main}
          >
            <KaCite content={infoContent} />
          </Box>
          <Box flexGrow={1}>
            <KaTaetigkeitsfeld>Finanzbuchhaltung</KaTaetigkeitsfeld>
            <KaTaetigkeitsfeld>Lohnbuchhaltung</KaTaetigkeitsfeld>
            <KaTaetigkeitsfeld last>
              Jahresabschluss und Steuererklärungen
            </KaTaetigkeitsfeld>
          </Box>
        </Box>

        <Box
          mt={1}
          width="100%"
          display="flex"
          flexDirection={xs ? "column" : "row"}
        >
          <Box flexGrow={1} mr={xs ? 0 : 1}>
            <KaTaetigkeitsfeld>
              Durchsetzungsberatung gegenüber dem Finanzamt
            </KaTaetigkeitsfeld>
            <KaTaetigkeitsfeld>Betriebsprüfungsbegleitung</KaTaetigkeitsfeld>
            <KaTaetigkeitsfeld>
              Betriebswirtschaftliche Beratung
            </KaTaetigkeitsfeld>
            <KaTaetigkeitsfeld last>Rating Beratung</KaTaetigkeitsfeld>
          </Box>
          <Box
            mt={xs ? 1 : 0}
            className={classes.img}
            style={{ backgroundImage: "url(" + Img + ")" }}
            width={xs ? "100%" : "55%"}
            flexShrink={0}
            height={xs ? 180 : 80 * 4 + 3 * theme.spacing(1)}
            bgcolor={theme.palette.primary.main}
          ></Box>
        </Box>

        <Box
          mt={1}
          width="100%"
          display="flex"
          flexDirection={xs ? "column" : "row"}
        >
          <Box
            flexGrow={1}
            flexShrink={0}
            flexBasis={xs ? "auto" : "1px"}
            mr={xs ? 0 : 1}
          >
            <KaTaetigkeitsfeld last={!xs}>
              Umstrukturierung von Unternehmen
            </KaTaetigkeitsfeld>
          </Box>
          <Box flexGrow={1} flexShrink={0} flexBasis={xs ? "auto" : "1px"}>
            <KaTaetigkeitsfeld last>
              Vermögens- und Altersvorsorgeberatung
            </KaTaetigkeitsfeld>
          </Box>
        </Box>

        <Box
          mt={1}
          width="100%"
          display="flex"
          flexDirection={xs ? "column" : "row"}
        >
          <Box
            mb={xs ? 1 : 0}
            width={xs ? "100%" : "55%"}
            flexShrink={0}
            height={xs ? 180 : 80 * 4 + 3 * theme.spacing(1)}
            mr={1}
            bgcolor={theme.palette.secondary.dark}
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="white"
          >
            <Box>
              <Box mb={3}>
                <Typography variant="h5" component="div" align="center">
                  Oder noch etwas
                  <br />
                  Spezielleres?
                </Typography>
              </Box>
              <Typography variant="h6" component="div" align="center">
                Jetzt Kontakt aufnehmen
              </Typography>
              <Typography variant="h5" component="div" align="center">
                0631/2057760
              </Typography>
            </Box>
          </Box>
          <Box flexGrow={1}>
            <KaTaetigkeitsfeld>Existenzgründungsberatung</KaTaetigkeitsfeld>
            <KaTaetigkeitsfeld>Unternehmensnachfolge</KaTaetigkeitsfeld>
            <KaTaetigkeitsfeld>Seniorenberatung</KaTaetigkeitsfeld>
            <KaTaetigkeitsfeld last>Testamentsvollstreckung</KaTaetigkeitsfeld>
          </Box>
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <KaDialog title="Tätigkeitsfelder" open={open} close={close}>
        {xs ? contentMobile : contentDesk}
      </KaDialog>
    </>
  );
};

export default SpektrumTaetigkeitsfelder;
