import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Carousel } from "react-responsive-carousel";
import Slide1 from "../../assets/slide_1.jpg";
import Slide2 from "../../assets/startseitenslider02.jpg";
import Slide3 from "../../assets/slider_impression.jpg";
import Slide4 from "../../assets/great-people_2.jpg";
import Siegel from "../../assets/siegel2022.png";
import KaBoxRow from "../shared/KaBoxRow";
import KaBox from "../shared/KaBox";
import useWindowSize from "../../hooks/useWindowSize";
import HomeIhreErwartungen from "./HomeIhreErwartungen";
import { Redirect, useHistory } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import HomeUnsereErwartungen from "./HomeUnsereErwartungen";
import HomeGemeinsamerWeg from "./HomeGemeinsamerWeg";
import useScreensize from "../../hooks/useScreensize";

const useStyles = makeStyles(theme => ({
  sliderWrapper: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2)
    }
  },
  siegel: {
    position: "absolute",
    right: theme.spacing(4),
    top: "10%",
    height: "80%",
    width: "100%",
    backgroundImage: "url(" + Siegel + ")",
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center"
  },
  slide: {
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    overflow: "hidden"
  },
  slideLink: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  btnPrev: {
    position: "absolute",
    left: theme.spacing(0.5),
    top: "50%",
    marginTop: -15,
    color: "white",
    padding: 0
  },
  btnNext: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: "50%",
    marginTop: -15,
    color: "white",
    padding: 0
  },
  btnSliderIcon: {
    width: 50,
    height: 50
  }
}));
const sliderImages = [
  { image: Slide1, showSiegel: true },
  { image: Slide4, showSiegel: false, link: "/kanzlei/karriere" },
  { image: Slide2, showSiegel: false },
  { image: Slide3, showSiegel: false }
];

const testColors = ["#eee", "#eee", "#ccc"];

const Home = ({ match }) => {
  const classes = useStyles();
  const [windowWidth, windowHeight] = useWindowSize();
  const [sliderHeight, setSliderHeight] = useState(0);
  const [currentDialog, setCurrentDialog] = useState(null);
  const [closed, setClosed] = useState(false);
  const dispatch = useDispatch();
  const currentSlideIndex = useSelector(state => state.slider.currentIndex);
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();
  const history = useHistory();

  let boxHeight = 160;
  if (md) {
    boxHeight = 180;
  }
  if (lg) {
    boxHeight = 220;
  }
  if (xl) {
    boxHeight = 220;
  }

  useEffect(() => {
    if (!match.params.dialog) {
      setClosed(false);
    }
    setCurrentDialog(match.params.dialog || null);
  }, [match.params.dialog]);

  useEffect(() => {
    let footerHeight = 200;
    if (sm) {
      footerHeight = 300;
    }
    if (!xs) {
      const availableHeight = windowHeight - 80 - boxHeight - 32 - footerHeight;
      setSliderHeight(availableHeight);
    } else {
      setSliderHeight(240);
    }
  }, [windowHeight, windowWidth]);

  const onPrev = () => {
    dispatch({ type: "SET_PREV_SLIDE" });
  };

  const onNext = () => {
    dispatch({ type: "SET_NEXT_SLIDE" });
  };

  const onUpdateSlider = event => {
    // console.log(event);
  };

  if (closed) {
    setTimeout(() => {
      history.push("/");
    }, 100);
    // return <Redirect to="/" push />;
  }

  let calculatedSlideIndex = currentSlideIndex % sliderImages.length;
  if (calculatedSlideIndex < 0) {
    calculatedSlideIndex = sliderImages.length + calculatedSlideIndex;
  }

  return (
    <>
      <div className={classes.sliderWrapper}>
        <Carousel
          autoPlay={!match.params.dialog}
          selectedItem={calculatedSlideIndex}
          onChange={onUpdateSlider}
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          swipeable={true}
          showStatus={false}
          interval={5000}
        >
          {sliderImages.map((item, index) => (
            <div
              key={index}
              className={classes.slide}
              style={{
                backgroundColor: testColors[index],
                backgroundImage: "url(" + item.image + ")",
                height: sliderHeight
              }}
            >
              {item.link && <a href={item.link} className={classes.slideLink}></a>}
              {item.showSiegel && <div className={classes.siegel} />}
            </div>
          ))}
        </Carousel>
        {!xs && (
          <>
            <IconButton className={classes.btnPrev} onClick={onPrev}>
              <ChevronLeftIcon className={classes.btnSliderIcon} />
            </IconButton>
            <IconButton className={classes.btnNext} onClick={onNext}>
              <ChevronRightIcon className={classes.btnSliderIcon} />
            </IconButton>
          </>
        )}
      </div>
      <KaBoxRow last>
        <KaBox
          subtitle="Ihre"
          title="Erwartungen"
          to="/home/ihre-erwartungen"
        ></KaBox>
        <KaBox
          subtitle="Unsere"
          title="Erwartungen"
          to="/home/unsere-erwartungen"
        ></KaBox>
        <KaBox
          subtitle="Gemeinsamer"
          title="Weg"
          to="/home/gemeinsamer-weg"
          last
        ></KaBox>
      </KaBoxRow>
      <HomeIhreErwartungen
        open={currentDialog === "ihre-erwartungen"}
        close={() => {
          setClosed(true);
        }}
      />
      <HomeUnsereErwartungen
        open={currentDialog === "unsere-erwartungen"}
        close={() => {
          setClosed(true);
        }}
      />
      <HomeGemeinsamerWeg
        open={currentDialog === "gemeinsamer-weg"}
        close={() => {
          setClosed(true);
        }}
      />
    </>
  );
};

export default Home;
