import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {
  Box,
  Container,
  Button,
  useMediaQuery,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { grey, blueGrey } from "@material-ui/core/colors";
import KaProgressBar from "./shared/feedbackComponents/KaProgressBar";
import Logo from "../assets/logo.png";
import { NavLink, Link, useLocation, useHistory } from "react-router-dom";
import MuiLink from "@material-ui/core/Link";
import useWindowSize from "../hooks/useWindowSize";
import { useTheme } from "@material-ui/styles";
import Kontakt from "./pages/Kontakt";
import KaFooter from "./shared/KaFooter";
import useScreensize from "../hooks/useScreensize";
import clsx from "clsx";
import KaSeo from "./shared/KaSeo";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  logo: {
    height: "100%",
    width: "auto",
    display: "block"
  },
  logoWrapper: {
    height: 36,
    width: 200,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: 45,
      width: 340
    }
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.dark,
    color: "white"
  },
  appbar: {
    [theme.breakpoints.down("xs")]: {
      boxShadow: "0 2px 3px rgba(0,0,0,0.3)"
    }
  },
  menuItem: {
    fontWeight: "bold",
    fontSize: "1.6rem",
    flexGrow: 1,
    margin: theme.spacing(1),
    padding: 0
  },
  appBarSpacer: {
    height: 80,
    [theme.breakpoints.down("xs")]: {
      height: 56
    }
  },
  indicator: {
    position: "fixed",
    top: 64,
    left: 0,
    right: 0,
    zIndex: 99,
    boxShadow: "0 2px 3px rgba(0,0,0,0.3)"
  },
  indicatorWrapper: {
    position: "relative",
    background: "white",
    height: theme.spacing(2)
  },
  indicatorActive: {
    background: theme.palette.secondary.main,
    position: "absolute",
    height: theme.spacing(2),
    transition: "right 0.4s ease, left 0.3s ease 0.1s"
  },
  footer: {
    padding: theme.spacing(0, 0, 4, 0),
    marginTop: "auto",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderTopWidth: theme.spacing(2),
    borderTopStyle: "solid",
    borderTopColor: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0),
      height: 300
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 0, 4, 0),
      height: 200
    },
    [theme.breakpoints.up("lg")]: {
      height: 200
    }
  },
  main: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      background: "white"
    }
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      background: "white",
      flexGrow: 1,
      padding: theme.spacing(0, 2)
    },
    [theme.breakpoints.up("lg")]: {
      borderLeftWidth: theme.spacing(2),
      borderLeftStyle: "solid",
      borderLeftColor: theme.palette.background.default,
      borderRightWidth: theme.spacing(2),
      borderRightStyle: "solid",
      borderRightColor: theme.palette.background.default
    }
  },
  toolbar: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  toolbarContainer: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  activeDrawerItem: {
    backgroundColor: theme.palette.primary.main
  }
}));

const menuItems = [
  { title: "Kanzlei", path: "/kanzlei" },
  { title: "Spektrum", path: "/spektrum" },
  { title: "Service", path: "/service" },
  { title: "Kontakt", path: "/kontakt" }
];

const classify = path => {
  if (path === "/") {
    return "home";
  }

  const categories = [
    "kanzlei",
    "spektrum",
    "service",
    "kontakt",
    "impressum",
    "datenschutz"
  ];
  return (categories.filter(c => path.indexOf(c) !== -1) || ["home"])[0];
};

const Layout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const [indicatorLeft, setIndicatorLeft] = useState(0);
  const [indicatorRight, setIndicatorRight] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [activeMenuItem, setActiveMenuItem] = useState(0);
  const [activeCategory, setActiveCategory] = useState(null);
  const [contactOpen, setContactOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [prevLocation, setPrevLocation] = useState(null);
  const open = Boolean(anchorEl);
  const menuItem1El = useRef();
  const menuItem2El = useRef();
  const menuItem3El = useRef();
  const menuItem4El = useRef();
  const menuItem5El = useRef();
  const wrapperEl = useRef();
  const [windowWidth, windowHeight] = useWindowSize();
  const theme = useTheme();
  const { xs, sm, md, lg, xl } = useScreensize();
  const history = useHistory();

  const menuItemElRefs = [
    menuItem1El,
    menuItem2El,
    menuItem3El,
    menuItem4El,
    menuItem5El
  ];

  useEffect(() => {
    // console.log(location);
    // console.log(history);
    window.scrollTo(0, 0);
  }, [activeCategory]);

  useEffect(() => {
    // console.log(location.pathname);
    let currentMenuItem = 0;
    const cat = classify(location.pathname);
    switch (cat) {
      case "home":
        currentMenuItem = 0;
        break;
      case "kanzlei":
        currentMenuItem = 1;
        break;
      case "spektrum":
        currentMenuItem = 2;
        break;
      case "service":
        currentMenuItem = 3;
        break;
      case "kontakt":
        currentMenuItem = 4;
        break;
      case "impressum":
        currentMenuItem = 5;
        break;
      case "datenschutz":
        currentMenuItem = 6;
        break;
      default:
        currentMenuItem = 0;
    }

    setActiveMenuItem(currentMenuItem);
    setActiveCategory(cat);

    if (xs || currentMenuItem > 4) {
      setIndicatorLeft(0);
      setIndicatorRight(0);
      return;
    }

    const wrapperWidth = wrapperEl.current.offsetWidth;
    const left = menuItemElRefs[currentMenuItem].current.offsetLeft;
    const width = menuItemElRefs[currentMenuItem].current.offsetWidth;
    const right = wrapperWidth - left - width;

    setIndicatorLeft(left);
    setIndicatorRight(right);
    setIndicatorWidth(width);
  }, [location, windowWidth, windowHeight]);

  // console.log("ACTIVE", activeMenuItem);

  const drawerItems = [
    { title: "Home", path: "/" },
    ...menuItems,
    { title: "Impressum", path: "/impressum" },
    { title: "Datenschutz", path: "/datenschutz" }
  ];
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {drawerItems.map((item, index) => (
          <ListItem
            className={clsx({
              [classes.activeDrawerItem]: activeMenuItem === index
            })}
            component={Link}
            to={item.path}
            onClick={() => {
              setMobileOpen(false);
            }}
            button
            key={index}
          >
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const indicatorStyle = {
    left: indicatorLeft,
    right: indicatorRight
  };

  const handleDrawerToggle = () => {
    setMobileOpen(true);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appbar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Container maxWidth="lg" className={classes.toolbarContainer}>
            <Box display="flex" alignItems="center">
              {xs && (
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <MuiLink
                component={Link}
                to="/"
                className={classes.logoWrapper}
                ref={menuItemElRefs[0]}
              >
                <img
                  src={Logo}
                  alt="raumundtextil24.de"
                  className={classes.logo}
                />
              </MuiLink>
              <Box display="flex" alignItems="center" flexGrow={3}>
                {!xs &&
                  menuItems.map((item, index) => (
                    <Button
                      ref={menuItemElRefs[index + 1]}
                      color="inherit"
                      className={classes.menuItem}
                      key={index}
                      component={Link}
                      to={item.path}
                    >
                      {item.title}
                    </Button>
                  ))}
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      {xs && (
        <nav className={classes.drawer} aria-label="Mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={() => {
              setMobileOpen(false);
            }}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      )}

      {!xs && (
        <div className={classes.indicator}>
          <div className={classes.indicatorWrapper} ref={wrapperEl}>
            <Box
              className={classes.indicatorActive}
              style={indicatorStyle}
            ></Box>
          </div>
        </div>
      )}

      <div className={classes.main}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.content}>
          {children}
        </Container>
      </div>
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <KaFooter />
        </Container>
      </footer>
      <KaProgressBar />
      <KaSeo category={activeCategory} />
    </div>
  );
};

export default Layout;
